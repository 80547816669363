@import '../../../styles/utils/variables';
@import '../../../styles/theme/theme';

.login-form {
  .ant-input, .ant-input-affix-wrapper{
   border-radius: 3px;
  }

  button[type='submit'] {
    border-radius: 3px;
  }

  .ant-form-item-explain-error{
    display: none;
  }

  &.restore {
    .ant-form-item {
      margin-bottom: 32px;
    }
  }
}

.search-form {
  width: 100%;

  .radio-btn-group{
    width: 100%;
    display: flex;
    justify-content: space-between;

      > .ant-radio-button-wrapper{
        padding: 0 12px;
        border-radius: 6px;
        border: none;
        border-inline-start-width: 1px;
        background: #EAECF0;
        width: 88px;
        text-align: center;

        &-checked{
          color: #344054;
          &:hover {
            color:  #e8317b;
          }
        }

        &:before{
          display: none;
        }

        .ant-radio-button{
          &-checked{
            border-radius: 5px;
            background: #D0D5DD;
          }
        }
      }

  }

  .ant-picker, .ant-select .ant-select-selector{
    background: #EAECF0;
    color: #344054;
  }

  .ant-select-selection-item{
    background: #D0D5DD;
  }

  .ant-select.custom-select:hover {
    .ant-select-selector {
      .ant-select-selection-placeholder {
        color:  #98A2B3;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .ant-form.ant-form-horizontal {
    width: 100%;
  }

  .login-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    button[type='submit'] {
      height: 72px;
      order: 1;
      margin-top: auto;
    }
    &.restore {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}

.ant-select .ant-select-clear {
  background: transparent;
}

//another type of mask password input
// .input-pass {
//   -webkit-text-security: square;
// }

:hover::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #98A2B3;
}
:hover::-moz-placeholder { /* Firefox 19+ */
  color: #98A2B3;
}
:hover:-ms-input-placeholder { /* IE 10+ */
  color: #98A2B3;
}
:hover:-moz-placeholder { /* Firefox 18- */
  color: #98A2B3;
}

.ant-picker-dropdown .ant-picker-header-view button {
  font-size: 16px;
}
