:root {
  --app-height: 100%;
}
//@import '~antd/dist/antd.less';
@import '../styles/theme/theme';
@import '../styles/utils/variables';

/* Layout */
body,
html {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  --antd-wave-shadow-color: transparent;
  color: @main;
  margin: 0;
}

a,
a:focus {
  color: @blue500;
}

a:hover,
a:active {
  color: @blue400;
}

svg {
  transition: fill 0.3s ease, opacity 0.3s ease;
}

/*.ant-input,
input {
  caret-color: @blue500 !important;
}*/

.main-layout {
  width: 100%;
  //height: 100vh;
  //height: var(--app-height);
}

.ant-btn{
  font-weight: 600;

  // &-primary {
  // //  color: #F6F8FC;
  // }
}

// .ant-table-column-sorter{
//  display: none;
// }

@media (max-width: 767px) {  }
